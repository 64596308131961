import React, { useState, useEffect } from "react";
import { Router, Redirect } from "@reach/router";
import configAbility from "../authorization/ability";
import DateFnsUtils from "@date-io/date-fns";
import { AbilityContext, Can } from "../authorization/abilities";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  // createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Loader from "../components/loader";

import { getLogout } from "../actions/authActions";
import { authUrlBase } from "../utils/urls";
import { useAuth } from "../hooks/useAuth";
import { primary, secondary } from "../utils/paletteApp";

import Dashboard from "./dashboard";
import UserEventsMain from "./notifications/notificationsMain";
import MenuContainer from "../components/menu/ContainerMenu";
import Container from "../components/Container";
import Login from "./login";
import MedicalNetwork from "./medicalNetwork/medicalNetwork";
import MedicalNetworkMain from "./medicalNetwork/medicalNetworkMain";
import FaqMain from "./faq/FaqMain";
import themeDefault from "../utils/theme";

import NewsMain from "./news/newsMain";
import News from "./news/news";
import NewsShow from "./news/newsShow";

import SinisterPolicy from "./sinisterPolicy";
import PolicyComponent from "./sinisterPolicy/policyComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    paddingBottom: 15,
    overflowX: "hidden",
  },
  footer:{
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "65px",
    },
  }
}));

const PublicRoute = ({ component: Component, ...rest }) => (
  <Component {...rest} />
);

const ProtectedRoute = ({ component: Component, action, subject, ...rest }) => {
  const token = useSelector((state) => state?.auth?.token);
  const isAuth = useAuth(token);
  const dispatch = useDispatch();

  if (isAuth) {
    if (action && subject) {
      return (
        <Can do={action} on={subject}>
          <Component {...rest} />
        </Can>
      );
    } else {
      return <Component {...rest} />;
    }
  } else {
    dispatch(getLogout());
    return window.location.assign(
      `${authUrlBase}login/insured?redirect=${window.location.origin}`
    );
  }
};

const Routes = () => {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const [theme, setTheme] = useState({ primary, secondary });
  const config = useSelector((state) => state.userConfig);
  const isAuth = useAuth(token);

  useEffect(() => {
    if (config.data.theme) {
      //console.log('config theme: ', config.data.theme);
      setTheme({
        primary: {
          // ...theme.primary,
          main: config.data.theme.primary.main, // principal
          dark: config.data.theme.primary.variant1, // varian1
        },
        secondary: {
          // ...theme.secondary,
          main: config.data.theme.secondary.main,
          dark: config.data.theme.secondary.variant1,
        },
        variants: {
          variant1: config.data.theme.secondary.variant2, //
          variant2: config.data.theme.secondary.variant3,
          variant3: config.data.theme.secondary.variant4,
          variant4: config.data.theme.secondary.variant5,
        },
        typography: {
          main: config.data.theme.typography.main,
          variant1: config.data.theme.typography.variant1,
        },
      });
    }
    if (config.data.favicon) {
      const favicon = document.getElementById("favicon");
      favicon.href = config.data.favicon;
    }
  }, [config.data]);

  const appTheme = createTheme({
    ...themeDefault,
    palette: {
      ...themeDefault.palette,
      ...theme,
    },
  });

  // console.log("appTheme: ", appTheme);
  //console.log('theme: ', appTheme, themeDefault);

  return (
    <MuiThemeProvider theme={appTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AbilityContext.Provider value={configAbility(token)}>
          <Router>
            <PublicRoute path="login" component={Login} />
          </Router>
          <div
            className={classes.root}
            style={{ minHeight: "98vh", position: "relative" }}
          >
            {isAuth && <MenuContainer />}
            <main className={classes.content}>
              <Container>
                <Router>
                  <Redirect from="/" to="login" noThrow />
                  <ProtectedRoute
                    action="read"
                    subject="Dashboard"
                    path="/dashboard"
                    component={Dashboard}
                  />
                  <ProtectedRoute
                    path="/notifications"
                    action="read"
                    subject="Notifications"
                    component={UserEventsMain}
                  />
                  <ProtectedRoute
                    path="medical-network"
                    action="read"
                    subject="MedicalNetWork"
                    component={MedicalNetwork}
                  >
                    <ProtectedRoute path="/" component={MedicalNetworkMain} />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path="faq"
                    action="read"
                    subject="Ayuda"
                    component={FaqMain}
                  />
                  <ProtectedRoute path="news" component={News}>
                    <ProtectedRoute path="/" component={NewsMain} />
                    <ProtectedRoute path="/read/:slug" component={NewsShow} />
                  </ProtectedRoute>
                  <ProtectedRoute path="sinister" component={SinisterPolicy}>
                    <ProtectedRoute
                      //action="read"
                      //subject="SinisterRegister"
                      path="/"
                      component={PolicyComponent}
                    />
                  </ProtectedRoute>
                </Router>
              </Container>
              <Loader isLoading={config.loading} solid />
            </main>
          </div>
          <footer 
            className={classes.footer}
          >
            <p style={{margin: '0 15px',fontSize:'small',paddingBottom: '10px'}}>
          Boulevard Merliot, Urbanización Jardines de la Hacienda,. Lote 5 y 6, Zona Comercial Z.C 5, Antiguo Cuscatlán. La Libertad, El Salvador, C.A., Tel: (503) 2521-8300 
            </p>
          </footer>
        </AbilityContext.Provider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default Routes;
