import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, SvgIcon, Typography } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { cardPayStyle } from "./style";
import { general } from "../../../../utils/icons";
import { INSURED_CLIENT } from "../../../../utils/urls";

const CardPay = ({ policy, handleOpenDrawer }) => {
  const [estado, setEstado] = useState("DEFAULT");
  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const statusMatch = {
    PENDIENTE: "#219653",
    VENCIDA: "#EB5757",
    ACTIVA: "#6FCF97",
    DEFAULT: "#000000",
  };
  useEffect(() => {
    if (policy?.importe_vencido > 0) {
      setEstado("VENCIDA");
    } else {
      setEstado(`${policy?.estado || "default"}`.toUpperCase());
    }
  }, []);
  const classes = cardPayStyle({
    client: INSURED_CLIENT,
    colorStatus: statusMatch[estado],
  });

  return (
    <Card className={classes.root} onClick={() => handleOpenDrawer()}>
      <CardContent className={classes.content}>
        <div className={classes.header}>
          <Grid container justifyContent="space-between">
            <div className={classes.status}>
              <SvgIcon>{general.oval}</SvgIcon>
            </div>
          </Grid>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.description}>{`Número de póliza: ${policy.nro_poliza}`}</Typography>
            <Typography className={classes.title}>
              {policy?.proxima_cuota_vencimiento
                ? moment(policy?.proxima_cuota_vencimiento).format("DD/MM/YYYY")
                : policy?.fecha_renovacion
                ? moment(policy?.fecha_renovacion).format("DD/MM/YYYY")
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={clsx({
                [classes.price]: true,
                [classes.stateColor]: true,
              })}
            >
              {formatNumber(`$${policy?.proxima_cuota_importe || 0}`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.actions} container justifyContent="space-between"></Grid>
      </CardContent>
    </Card>
  );
};

export default CardPay;
